import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	data: {},
});

export default createReducer(initialState, {
	[types.SET_CUSTOMER_SEGMENTATION]: (state, { payload }) => state.set('data', payload),
});
