import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { clientSegmentation } from '@/services/api';
import t from '@/utilities/translate';
import { noty } from '@/utilities/notifications';
import { store } from '@/redux/store';

//-----------------------------------------
//    Triggers
//-----------------------------------------

export const triggers = {};

triggers.fetchClientSegmentationsList = (query) => ({
	type: types.FETCH_CLIENT_SEGMENTATIONS_LIST,
	query,
});

triggers.fetchClientSegmentationsFullList = () => ({
	type: types.FETCH_CLIENT_SEGMENTATIONS_FULL_LIST,
});

triggers.fetchCurrentClientSegmentation = (id) => ({
	type: types.FETCH_CURRENT_CLIENT_SEGMENTATION,
	id,
});

triggers.editClientSegmentation = (payload) => ({
	type: types.EDIT_CLIENT_SEGMENTATION,
	payload
});

const putClientSegmentationsList = (payload) => ({
	type: types.SET_CLIENT_SEGMENTATIONS_LIST,
	payload,
});

const putClientSegmentationsFullList = (payload) => ({
	type: types.SET_CLIENT_SEGMENTATIONS_FULL_LIST,
	payload,
});

const putCurrentClientSegmentation = (payload) => ({
	type: types.SET_CURRENT_CLIENT_SEGMENTATION,
	payload,
});

const putFilter = (payload) => ({
	type: types.SET_FILTER,
	payload,
});

//-----------------------------------------
//	Saga actions
//-----------------------------------------

export function* fetchClientSegmentationsList({ type, query }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(clientSegmentation.list, query);
		yield put(putClientSegmentationsList(data));
		yield put(putFilter(query));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* fetchClientSegmentationsFullList() {
	try {
		const { data } = yield call(clientSegmentation.fullList);
		yield put(putClientSegmentationsFullList(data));
	} catch (e) {
		yield call(console.warn, e);
	}
}

export function* fetchCurrentClientSegmentation({ type, id }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(clientSegmentation.current, id);
		yield put(putCurrentClientSegmentation(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

export function* editClientSegmentation({ type, payload }) {
	const { id, percentage } = payload;

	yield put(addPreloader(type));
	try {
		yield call(clientSegmentation.update, id, { percentage });
		yield call(noty, 'success', t('client-segmentation/update/success'));
		const filter = store.getState().clientSegmentation.get('filter');
		yield put(triggers.fetchClientSegmentationsList(filter));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root saga
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_CLIENT_SEGMENTATIONS_LIST, fetchClientSegmentationsList);
	yield takeEvery(types.FETCH_CLIENT_SEGMENTATIONS_FULL_LIST, fetchClientSegmentationsFullList);
	yield takeEvery(types.FETCH_CURRENT_CLIENT_SEGMENTATION, fetchCurrentClientSegmentation);
	yield takeEvery(types.EDIT_CLIENT_SEGMENTATION, editClientSegmentation);
}
