import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { customerSegmentation } from '@/services/api';

//-----------------------------------------
//	Triggers
//-----------------------------------------
export const triggers = {};

triggers.fetchCustomerSegmentationData = (query) => ({
	type: types.FETCH_CUSTOMER_SEGMENTATION,
	query
});

const putCustomerSegmentationsList = (payload) => ({
	type: types.SET_CUSTOMER_SEGMENTATION,
	payload,
});

//-----------------------------------------
//	Actions
//-----------------------------------------

export function* fetchCustomerSegmentationData({ type, query }) {
	yield put(addPreloader(type));
	try {
		const { data } = yield call(customerSegmentation.getData, query);
		yield put(putCustomerSegmentationsList(data));
	} catch (e) {
		yield call(console.warn, e);
	} finally {
		yield put(removePreloader(type));
	}
}

//-----------------------------------------
//	Root dealer saga
//-----------------------------------------

export function* saga() {
	yield takeEvery(types.FETCH_CUSTOMER_SEGMENTATION, fetchCustomerSegmentationData);
}
