import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Input, Form } from 'antd';
import t from '@/utilities/translate';
import { triggers } from '@/redux/client-segmentation/actions';
import DispatchOnMount from '@/components/common/DispatchOnMount';

const Edit = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		editClientSegmentation,
		fetchCurrentClientSegmentation,
		segmentationId,
		data,
	} = props;
	const [name, setName] = useState('');
	const [percentage, setPercentage] = useState('');

	const closeModal = () => {
		editClientSegmentation({
			id: segmentationId,
			percentage: Number(percentage),
		});
		closeModalFn();
	};

	useEffect(() => {
		if (data) {
			setName(data.name);
			setPercentage(data.percentage);
		}
	}, [data.name, data.percentage]);

	const handlePercentageChange = (event) => {
		setPercentage(event.target.value);
	};

	return (
		<Modal {...modalProps}
			   okButtonProps={{ disabled: percentage === '' }}
			   onOk={closeModal}
			   okText={t('common/form/save')}
		>
			<DispatchOnMount
				actionCreator={segmentationId ? () => fetchCurrentClientSegmentation(segmentationId) : () => {
				}}>
				<Form>
					<Form.Item style={{ marginBottom: 0 }} label={t('client-segmentation/name/label')}>
						<Input disabled={true} value={name} type={'text'} onChange={handlePercentageChange}
							   style={{ width: '100%' }}/>
					</Form.Item>
					<Form.Item style={{ marginBottom: 0 }} label={t('client-segmentation/percentage/label')}>
						<Input value={percentage} type={'number'} onChange={handlePercentageChange}
							   style={{ width: '100%' }}/>
					</Form.Item>
				</Form>
			</DispatchOnMount>
		</Modal>
	);
};

Edit.defaultProps = {
	modalProps: {},
};

Edit.propTypes = {
	modalProps: PropTypes.any,
	closeModal: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		data: state.clientSegmentation.get('current'),
	}),
	{
		...triggers,
	},
)(Edit);
