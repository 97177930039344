/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import isMobilePhone from 'validator/lib/isMobilePhone';
import t from '@/utilities/translate';
import { requires, rulesHandler } from '@/utilities/validation';


export default {
  userProfileFields: (_type_) => (values, props) => {
    // REQUIRES

    const validateWithSupervisor = values.role_id === 1 && ['supervisor_id', 'coworker_id'];
    const validateWithTopManager = values.role_id === 2 && ['coworker_id'];
    const validateWithLoginAndPass = [].concat(
      (props.passGen !== undefined && !props.passGen) && ['password'],
      (props.logGen !== undefined && !props.logGen) && ['login'],
    );
    const disableLocationValidByActiveSales = [].concat(
      values.active_sales ? ['country_id'] : [],
    );

    const err = Object.assign(
      rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
        'name',
        'second_name',
        'last_name',
      ]),
      requires(_type_ === 'create' ? [
        'jd_id',
        'name',
        'last_name',
        'phone',
        'email',
        'role_id',
        'active_sales',
        'dealer_id',
        'country_id',
        'region_id',
        'district_ids',
      ].concat(
        validateWithLoginAndPass,
        validateWithSupervisor,
        validateWithTopManager,
        disableLocationValidByActiveSales,
      ) : [
        'jd_id',
        'name',
        'last_name',
        'phone',
        'email',
        'role_id',
        'active_sales',
        'dealer_id',
        'country_id',
        'region_id',
        'district_ids',
      ].concat(
        validateWithSupervisor,
        disableLocationValidByActiveSales,
      ))(values),
    );

    // Others validation

    // if (values.phone) {
    //   const phoneNumber = values.phone.replace(/\s|\(|\)|-|_/g, '');
    //   if (!isMobilePhone(phoneNumber, 'uk-UA')) {
    //     err.phone = t('employees/error/phone/not_number');
    //   }
    // }

    if (!props.logGen && values.login && !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(values.login)) { err.login = t('employees/error/login/latin'); }

    // if (!props.passGen && values.password && values.password !== '' && values.password !== null && values.password.length < 8)
    //     err.password = t('employees/error/password/password_min_char');

    // if (values.email && values.email.indexOf('@') < 0) { err.email = t('employees/error/email/email_data'); }

    if (values.email) {
      const handlerResult = rulesHandler.email(values.email);
      if (handlerResult) { err.email = handlerResult; }
    }

    if (!props.passGen && !values.password) { err.password = t('validate/required-field'); }

    if (values.role_id && (values.role_id > 6 || values.role_id < 1)) { err.role_id = t('error/role'); }
    return err;
  },

  vehiclesFields: (values) => {
    const err = requires([
      'manufacturer_id',
      'platform_id',
      'equipment_group_id',
      'model_description_id',
      'equipment_type',
      'product_line_group_id',
    ])(values);

    return err;
  },

  tradeInVehicle: (values) => {
    const err = requires([
      'client_vehicle_id',
    ])(values);

    return err;
  },

  vehiclesEditFields: (values) => {
    const err = requires([
      'manufacturer_id',
      'product_id',
      'quantity',
      'offered_price',
      'currency_id'
    ])(values);

    return err;
  },

  manufactureFields: (values) => {
    const err = Object.assign(
      requires([
        'relationship',
        'name',
      ])(values),
      rulesHandler.reduce(values, 'onlyLatin')([
        'name',
      ]),
    );

    // if (values.ru && !/([а-я]|[А-Я])+/.test(values.ru))
    //     err.ru = t('manufacture/field/validate/kirilic');

    return err;
  },

  sizeClass: (values, { sizeClassCount }) => {
    const err = requires([
      'equipment_group_id',
    ])(values);

    err.name = [];
    values.name = values.name || [];

    for (let i = 0; i < sizeClassCount; i++) {
      if (!values.name[i]) { err.name[i] = t('employees/error/role/role_data'); }
      if (/[А-Яа-я]/.test(values.name[i])) { err.name[i] = t('platform/size_class/create/name/latin'); }
    }

    return err;
  },

  productFields: (values) => {
    const err = requires([
      'model_name',
      'manufacture_id',
      'product_line_group_id',
      'product_line_id',
      'composite_level_id',
      'estimate_description_id',
      'platform_id',
      'equipment_group_id',
      'model_description_id',
    ])(values);

    if (!/[a-zA-Z]/.test(values.model_name)) // eslint-disable-line no-useless-escape
    { err.model_name = t('platform/size_class/create/name/latin'); }

    return err;
  },

  total_countrys_goal_filter: (values) => {
    const err = requires([
      'equipment_type',
      'year',
      'fy_type',
      'country_id',
      'manufacture_id',
      'product_line_group_id'
    ])(values);
    return err;
  },

  orderScheduledHistoryTotal: (values) => {
    const err = requires([
      'equipment_group_id',
      'country_id',
      'manufacturer_id',
    ])(values);
    return err;
  },

  orderScheduledHistory: (values) => {
    const err = requires([
      'dealer_id',
      'equipment_group_id',
      'country_id',
      'manufacturer_id',
    ])(values);
    return err;
  },

  total_countrys_manufacture_filter: (values) => requires([
    'dealer_id',
    'equipment_group_id',
  ])(values),

  dealerFields: (type) => (values, props) => {
    const err = Object.assign(
      rulesHandler.reduce(values, 'onlyLatin')([
        'name',
      ]),
      rulesHandler.reduce(values, 'wordsAndSymbol')([
        'legal_address_city',
        'legal_address',
        'legal_address2',
      ]),
      requires([
        'jd_id',
        'name',
        'phone',
        'email',
        'legal_address_country_id',
        'legal_address_region_id',
        'legal_address_district_id',
        'legal_address_city',
        'legal_address',
        'country_id',
        'region_ids',
        'district_ids',
        'territorial_id',
      ].concat((props.passGen !== undefined && !props.passGen)
        ? ['password']
        : [], (props.logGen !== undefined && !props.logGen)
        ? ['login']
        : []))(values),
    );

    if (!props.logGen && values.login && !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(values.login)) { err.login = t('employees/error/login/latin'); }

    // if (values.index && !/^[0-9]{5}$/.test(values.index)) { err.index = t('dealer/create/index/valid'); }
    if (!props.passGen && values.password && values.password.length < 8) {
      err.password = t('dealer/create/index/valid');
    }

    if (values.email) {
      const handlerResult = rulesHandler.email(values.email);
      if (handlerResult) { err.email = handlerResult; }
    }

	// if (values.phone) {
	//   const phoneNumber = values.phone.replace(/\s|\(|\)|-|_/g, '');
	//   if (!isMobilePhone(phoneNumber, 'uk-UA')) {
	// 	  err.phone = t('employees/error/phone/not_number');
	//   }
	// }

    return err;
  },

  clientFields: (type) => (values, props) => {
    const err = Object.assign(
      requires([
        'customer_id',
        'client_segmentation_id',
        'company_name',
        'customer_first_name',
        'customer_last_name',
        'tax_code',
        'company_code',
        'country_id',
        'region_id',
        'district_ids',
        'district_id',
        'region_ids',
        'address_city',
        'address',
        'phone',
      ])(values),
    );

    if (values.index && values.index.length && String(values.index).length !== 5) {
      err.index = t('validate/length').replace('{num}', '5');
    }

    if (!isNaN(values.cattle) && values.cattle < 0) {
      err.cattle = t('client/validate/pet_count');
    }

    if (!isNaN(values.cows) && values.cows < 0) {
      err.cows = t('client/validate/pet_count');
    }

    if (!isNaN(values.pigs) && values.pigs < 0) {
      err.pigs = t('client/validate/pet_count');
    }

    if (!isNaN(values.chickens) && values.chickens < 0) {
      err.chickens = t('client/validate/pet_count');
    }

    if (values.profile_number) {
      if (!/^[0-9]{7}$/.test(values.profile_number)) { err.profile_number = t('client/validate/profile_number'); }
    }

    if (values.company_code && !/^[0-9]{8}$/.test(values.company_code)) {
      if (!/^[0-9]{8}$/.test(values.company_code)) { err.company_code = t('client/validate/company_code'); }
    }

    if (values.email) {
      const handlerResult = rulesHandler.email(values.email);
      if (handlerResult) { err.email = handlerResult; }
    }

    return err;
  },

  clientLocationsFields: (values) => Object.assign(
	requires([
	  'country_id',
	  'region_id',
	  'district_id',
	])(values),
  ),

  contactFields: (values, props) => {
    const err = Object.assign(
      requires([
        'first_name',
        'last_name',
        'phone',
      ])(values),
    );

    if (values.email) {
      const handlerResult = rulesHandler.email(values.email);
      if (handlerResult) { err.email = handlerResult; }
    }

    return err;
  },

  territorialFields: (values, props) => {
    const passsAndLoginRequired = [].concat((props.passGen !== undefined && !props.passGen)
      ? ['password']
      : [], (props.logGen !== undefined && !props.logGen)
      ? ['login']
      : []);

    const err = _.assign(
      rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
        'name',
        'last_name',
        'second_name',
      ]),
      requires([
        'jd_id',
        'name',
        'last_name',
        // 'second_name',
        'phone',
        'email',
        'role_id',
        'dealer_id',
        'country_id',
        'region_id',
        'district_ids',
        'manufacturer',
        // 'birthday'
      ].concat(passsAndLoginRequired))(values),
    );

    return err;
  },
  pssFields: (values, props) => {
    const passsAndLoginRequired = [].concat((props.passGen !== undefined && !props.passGen)
      ? ['password']
      : [], (props.logGen !== undefined && !props.logGen)
      ? ['login']
      : []);

    const err = _.assign(
      rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
        'name',
        'last_name',
        'second_name',
      ]),
      requires([
        'jd_id',
        'name',
        'last_name',
        // 'second_name',
        'phone',
        'email',
        'role_id',
        'dealer_id',
        'country_id',
        'region_id',
        'district_ids',
	    'manufacturer',
        // 'birthday'
      ].concat(passsAndLoginRequired))(values),
    );

    // if (values.phone) {
    //   const phoneNumber = values.phone.replace(/\s|\(|\)|-|_/g, '');
    //   if (!isMobilePhone(phoneNumber, 'uk-UA')) {
    //     err.phone = t('employees/error/phone/not_number');
    //   }
    // }

    if (values.second_name && !/^[a-zA-Z .-]+$/.test(values.second_name)) {
      err.second_name = t('employees/error/second_name/latin');
    }

    if (!props.passGen && values.password && values.password.length < 8) { err.password = t('dealer/create/index/valid'); }

    if (values.email) {
      const handlerResult = rulesHandler.email(values.email);
      if (handlerResult) { err.email = handlerResult; }
    }

    return err;
  },

  countryFields: (values, props) => _.assign(
    requires([
      'name',
    ])(values),

  ),
  regionFields: (values, props) => _.assign(
    requires([
      'name',
      'country_id',
    ])(values),
  ),
  job: (values, props) => _.assign(
    rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
      'name',
    ]),
    requires([
      'name',
    ])(values),
  ),
  factories: (values, props) => _.assign(
      rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
        'name',
      ]),
      rulesHandler.reduce(values, 'integer', true)([
        'lead_time', 'ship_time', 'risk_time',
      ]),
      requires([
        'name',
      ])(values),
  ),
  sizeParameters: (values, props) => _.assign(
    rulesHandler.reduce(values, 'sizeParameterName')([
      'name',
    ]),
    requires([
      'name',
    ])(values),
  ),
  productLineGroup: (values, props) => {
    const err = _.assign(
      requires([
        'ru.name',
        'en.name',
        'ua.name',
        'platform_id',
      ])(values),
      rulesHandler.reduce(values, 'onlyLatin')([
        'en.name',
      ]),
      rulesHandler.reduce(values, 'wordsAndSymbol')([
        'ru.name',
      ]),
    );

    return err;
  },
  farmSize: (values, props) => requires([
    'from',
    'to',
  ])(values),
  productLine: (values, props) => _.assign(
    requires([
      'platform_id',
      'product_line_group_id',
      'ru.name',
      'en.name',
    ])(values),
    rulesHandler.reduce(values, 'onlyLatin')([
      'en.name',
    ]),
    rulesHandler.reduce(values, 'wordsAndSymbol')([
      'ru.name',
    ]),
  ),
  equipmentGroup: (values, props) => _.assign(
    requires([
      'platform_id',
      'product_line_group_id',
      'product_line_id',
      'ru.name',
      'en.name',
      'ua.name',
    ])(values),
    rulesHandler.reduce(values, 'onlyLatin')([
      'en.name',
    ]),
    rulesHandler.reduce(values, 'wordsAndSymbol')([
      'ru.name',
    ]),
  ),
  compositeLevel: (values, props) => _.assign(
    requires([
      'platform_id',
      'product_line_group_id',
      'product_line_id',
      'equipment_group_id',
      'ru.name',
      'en.name',
      'ua.name',
    ])(values),
    rulesHandler.reduce(values, 'onlyLatin')([
      'en.name',
    ]),
    rulesHandler.reduce(values, 'wordsAndSymbol')([
      'ru.name',
    ]),
  ),

  estimateDescription: (values) => _.assign(
    requires([
      'platform_id',
      'product_line_group_id',
      'product_line_id',
      'equipment_group_id',
      'composite_level_id',
      'ru.name',
      'en.name',
      'ua.name',
    ])(values),
    rulesHandler.reduce(values, 'onlyLatin')([
      'en.name',
    ]),
  ),

  modelDescription: (values) => _.assign(
    requires([
      'platform_id',
      'product_line_group_id',
      'product_line_id',
      'equipment_group_id',
      'composite_level_id',
      'estimate_description_id',
      'ru.name',
      'en.name',
      'ua.name',
    ])(values),
    rulesHandler.reduce(values, 'onlyLatin')([
      'en.name',
    ]),
  ),

  baseMachineryDescription: (values) => _.assign(
    requires([
      'platform_id',
      'product_line_group_id',
      'product_line_id',
      'equipment_group_id',
      'composite_level_id',
      'estimate_description_id',
      'model_description_id',
      'ru.name',
      'en.name',
      'ua.name',
    ])(values),
    rulesHandler.reduce(values, 'onlyLatin')([
      'en.name',
    ]),
  ),

  coWorkerFields: (type) => (values, props) => {
    const err = Object.assign(
      rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
        'name',
        'last_name',
        'second_name',
      ]),
      requires([
        'jd_id',
        'name',
        'phone',
        'email',
        'last_name',
      ].concat((values.passGen !== undefined && !values.passGen)
        ? ['password']
        : [], (values.logGen !== undefined && !values.logGen)
        ? ['login']
        : []))(values),
    );

    if (!props.logGen && values.login && !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(values.login)) { err.login = t('employees/error/login/latin'); }
    if (!props.passGen && values.password && values.password !== '' && values.password !== null && values.password.length < 8) { err.password = t('employees/error/password/password_min_char'); }

    if (!props.passGen && values.password && values.password.length < 8) { err.password = t('dealer/create/index/valid'); }

    if (values.email) {
      const handlerResult = rulesHandler.email(values.email);
      if (handlerResult) { err.email = handlerResult; }
    }

    console.warn(err, 'err');

    return err;
  },

  currencyFields: (values) => Object.assign(
    requires([
      'name',
    ])(values),
    rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
      'name',
    ]),
  ),
  changeCredentials: (values) => {
    const errors = {};
    if (values.password) {
      if (values.password.length < 8) {
        errors.password = t('error/min-length/minLength').replace('minLength', 8);
      }
    }
    if (values.login) {
      if (values.login.length < 8) {
        errors.login = t('error/min-length/minLength').replace('minLength', 8);
      }
    }
    return Object.assign(
      requires(['login', 'password'])(values),
      errors
    )
  },
  changeDealStatus: (values) => {
    return Object.assign(
      requires(['login', 'password'])(values)
    )
  },
  dealStatusFields: (values) => Object.assign(
    requires([
      'en',
      'ru',
      'ua'
    ])(values),
    rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
      'en',
    ]),
    rulesHandler.reduce(values, 'onlyRusWithoutNumber')([
      'ru',
    ]),
  ),
  notificationEmails: (values) => Object.assign(
    requires([
      'user_id'
    ])(values)
  ),
  dealActionsFields: (values) => Object.assign(
    requires([
      'en',
      'ru',
      'ua'
    ])(values),
    rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
      'en',
    ]),
    rulesHandler.reduce(values, 'onlyRusWithoutNumber')([
      'ru',
    ]),
  ),
  dealResultsFields: (values) => Object.assign(
    requires([
      'en',
      'ru',
      'ua',
      'actionsIds',
    ])(values),
    rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
      'en',
    ]),
    rulesHandler.reduce(values, 'onlyRusWithoutNumber')([
      'ru',
    ]),
  ),
  dealLostReasonsFields: (values) => Object.assign(
    requires([
      'en',
      'ru',
      'ua'
    ])(values),
    rulesHandler.reduce(values, 'onlyLatinWithoutNumber')([
      'en',
    ]),
    rulesHandler.reduce(values, 'onlyRusWithoutNumber')([
      'ru',
    ]),
  ),
  serviceCodesFields: (values) => Object.assign(
	requires([
		'name',
		'code',
		'productLineIds',
	])(values),
  ),
  actualSalesPlanDraftFields: (values) => Object.assign(
	requires([
		'company_lost_deal_id',
		// 'service_code_id',
		'description_product_status',
		'description_product_model_description_id',
		'count',
		'cost',
		'amount',
		'client_id',
	])(values),
  ),
  actualSalesPlansFields: (values) => Object.assign(
	requires([
		'company_lost_deal_id',
		'count',
		'cost',
		'amount',
		'client_id',
		'equipment_type',
		'year',
	])(values),
  ),
  equipmentOperationTimesFields: (values) => Object.assign(
	requires([
		'equipment_type',
		'manufacture_id',
		'dealer_id',
    ])(values),
  ),
  potentialSalesAddProductFields: (values) => Object.assign(
    requires([
		'manufacturer_id',
		'model_description_id',
		'product_id',
		'quantity',
		'offered_price',
		'currency_id',
		'equipment_type',
    ].concat((!values.items?.length) ? ['fiscal_year'] : []))(values),
  )
};
