export const FETCH_CLIENT_SEGMENTATIONS_LIST = '@/client-segmentation/FETCH_CLIENT_SEGMENTATIONS_LIST';
export const SET_CLIENT_SEGMENTATIONS_LIST = '@/client-segmentation/SET_CLIENT_SEGMENTATIONS_LIST';

export const FETCH_CLIENT_SEGMENTATIONS_FULL_LIST = '@/client-segmentation/FETCH_CLIENT_SEGMENTATIONS_FULL_LIST';
export const SET_CLIENT_SEGMENTATIONS_FULL_LIST = '@/client-segmentation/SET_CLIENT_SEGMENTATIONS_FULL_LIST';

export const FETCH_CURRENT_CLIENT_SEGMENTATION = '@/client-segmentation/FETCH_CURRENT_CLIENT_SEGMENTATION';
export const SET_CURRENT_CLIENT_SEGMENTATION = '@/client-segmentation/SET_CURRENT_CLIENT_SEGMENTATION';

export const SET_FILTER = '@/client-segmentation/SET_FILTER';

export const EDIT_CLIENT_SEGMENTATION = '@/client-segmentation/EDIT_CLIENT_SEGMENTATION';
