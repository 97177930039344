import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';

const initialState = new Map({
	list: {},
	current: {},
	filter: {},
	fullList: [],
});

export default createReducer(initialState, {
	[types.SET_CLIENT_SEGMENTATIONS_LIST]: (state, { payload }) => state.set('list', payload),
	[types.SET_CLIENT_SEGMENTATIONS_FULL_LIST]: (state, { payload }) => state.set('fullList', payload),
	[types.SET_FILTER]: (state, { payload }) => state.set('filter', payload),
	[types.SET_CURRENT_CLIENT_SEGMENTATION]: (state, { payload }) => state.set('current', payload),
});
